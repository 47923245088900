<template>
  <div v-if="shouldShow" class="terms-of-service">
    <div class="fixed inset-0 w-full h-full bg-black/60 z-[99999]"></div>
    <div
      v-if="currentStep === 1"
      class="fixed inset-0 h-[90vh] m-auto bg-neutral-50 rounded-xl shadow-2xl flex flex-col z-[999999] overflow-hidden px-12 pb-12 pt-36 tos"
    >
      <div class="max-h-[80vh] overflow-y-auto content-scroll" @scroll="scroll">
        <div class="tos-contents">
          <div class="tos-text" v-html="termsOfService.content"></div>
        </div>
        <!-- <div class="tos-agree" v-if="!stillReading"> -->
        <div className="flex gap-2">
          <ReusableUICheckbox
            v-model="agreed"
            :checked="'I have read and agreed to the terms and conditions.'"
            :unchecked="'I have read and agreed to the terms and conditions.'"
            class="tos-agree-checkbox"
            :disabled="stillReading"
          />
        </div>
        <ReusableUIButtonsButtonPrimary
          size="medium"
          text="Agree"
          class="tos-agree-btn mt-6 self-end w-full mx-auto"
          @click="agree"
        />
        <!-- </div> -->
      </div>
    </div>
    <div
      v-else-if="currentStep !== 1"
      class="fixed inset-0 m-auto w-[90vw] max-w-[720px] bg-neutral-50 rounded-xl shadow-2xl text-center flex flex-col gap-5 h-fit z-[999999] overflow-hidden px-12 pb-12 pt-36 bg-image dark:bg-neutral-900"
    >
      <div
        v-if="currentStep == 2"
        class="w-full flex flex-col gap-6 h-full max-h-[500px] min-h-[500px] justify-center overflow-y-auto content-scroll"
      >
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-2 text-left -mt-10">
            <h1
              class="font-semibold text-2xl/none text-neutral-800 dark:text-neutral-200"
            >
              {{ $t("WMStepTwoTitle") }}
            </h1>
            <p
              class="font-normal text-base/none text-neutral-600 dark:text-neutral-400"
            >
              {{ $t("WMStepTwoDescription") }}
            </p>
          </div>
          <!-- <div class="flex items-center gap-2">
            <div class="flex items-center gap-1">
              <a
                :href="$t('WMStepTwoLink1')"
                target="_blank"
                class="font-normal text-sm/none text-blue-600"
              >
                {{ $t("WMStepTwoLink1Text") }}
              </a>
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.21295 6.24746C4.34964 6.11078 4.34964 5.88917 4.21295 5.75249L0.637077 2.17661C0.285229 1.82476 0.285229 1.25431 0.637077 0.902458C0.988925 0.55061 1.55938 0.55061 1.91123 0.902458L6.05299 5.04421C6.58084 5.57207 6.58084 6.42788 6.05299 6.95573L1.91123 11.0975C1.55938 11.4493 0.988924 11.4493 0.637077 11.0975C0.285229 10.7456 0.285229 10.1752 0.637077 9.82334L4.21295 6.24746Z"
                  fill="#2563EB"
                />
              </svg>
            </div>
            <div class="border border-l border-neutral-400 h-3.5"></div>
            <div class="flex items-center gap-1">
              <a
                :href="$t('WMStepTwoLink2')"
                target="_blank"
                class="font-normal text-sm/none text-blue-600"
              >
                {{ $t("WMStepTwoLink2Text") }}
              </a>
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.21295 6.24746C4.34964 6.11078 4.34964 5.88917 4.21295 5.75249L0.637077 2.17661C0.285229 1.82476 0.285229 1.25431 0.637077 0.902458C0.988925 0.55061 1.55938 0.55061 1.91123 0.902458L6.05299 5.04421C6.58084 5.57207 6.58084 6.42788 6.05299 6.95573L1.91123 11.0975C1.55938 11.4493 0.988924 11.4493 0.637077 11.0975C0.285229 10.7456 0.285229 10.1752 0.637077 9.82334L4.21295 6.24746Z"
                  fill="#2563EB"
                />
              </svg>
            </div>
          </div> -->
        </div>
        <iframe
          width="420"
          height="250"
          :src="$t('WMStepTwoVideoLink')"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
          style="
            width: 99.9% !important;
            margin: 0 auto;
            height: 350px !important;
            border-radius: 16px;
          "
        ></iframe>
      </div>
      <div
        class="flex flex-col gap-10 h-full max-h-[500px] min-h-[500px] overflow-y-auto content-scroll"
        v-if="currentStep == 3"
      >
        <div class="flex flex-col gap-3">
          <h2 class="font-bold text-2xl text-neutral-800 dark:text-neutral-200">
            {{ $t("levelSelect") }}
          </h2>
          <p
            class="font-normal text-base text-neutral-600 dark:text-neutral-400"
          >
            {{ $t("levelHelp") }}
          </p>
          <p
            class="font-normal text-base text-neutral-600 dark:text-neutral-400"
          >
            {{ $t("levelHelpSecondary") }}
          </p>
        </div>
        <div class="flex flex-col gap-3 w-full mx-auto items-center">
          <div
            v-for="item in userInfo"
            class="px-5 py-4 w-full border rounded-md flex gap-3.5 items-center transition-all ease-in-out duration-300 cursor-pointer"
            :class="
              item.type === experienceLevel
                ? 'border-blue-600 bg-blue-50/20 dark:border-blue-400 hover:bg-blue-50 dark:bg-blue-400/10'
                : 'bg-neutral-100/40 dark:bg-neutral-800 border-neutral-300/60 dark:border-neutral-700 hover:bg-neutral-200/40'
            "
            @click="setExperienceLevel(item.type)"
          >
            <div class="text-neutral-600 dark:text-neutral-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-8 h-8"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
            <div class="flex flex-col gap-2 items-start">
              <h1
                class="font-medium text-lg/none text-neutral-800 dark:text-neutral-200"
              >
                {{ item.heading }}
              </h1>
              <p
                class="font-normal text-sm text-neutral-600 dark:text-neutral-400"
              >
                {{ item.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        v-if="currentStep == 4"
        class="w-full flex flex-col gap-10 h-full max-h-[500px] min-h-[500px] justify-center"
      >
        <iframe
          width="420"
          height="250"
          src="https://player.vimeo.com/video/958515423?autoplay=1&amp;title=0&amp;byline=0&amp;portrait=0&amp;dnt=1"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
          style="
            width: 99.9% !important;
            margin: 0 auto;
            height: 350px !important;
            border-radius: 16px;
          "
        ></iframe> -->
      <!-- <div class="flex flex-col gap-3">
          <h1 class="font-bold text-2xl text-neutral-800 dark:text-neutral-200">
            {{ $t("themeSelect") }}
          </h1>
          <p
            class="font-medium text-base text-neutral-600 dark:text-neutral-400"
          >
            {{ $t("themeHelp") }}
          </p>
        </div>
        <div class="w-full grid grid-cols-2 gap-6">
          <div
            @click="changeTheme('light')"
            class="border rounded-lg overflow-hidden cursor-pointer transition-all ease-in-out duration-300 customScale group"
            :class="
              theme == 'light'
                ? 'border-blue-600 bg-blue-50/20 dark:border-blue-400 dark:bg-blue-400/10'
                : 'border-neutral-300'
            "
          >
            <div class="p-3 flex gap-2 items-center bg-neutral-100">
              <svg
                width="20"
                class="text-neutral-800 dark:text-neutral-200"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 3V5.25M18.364 5.63604L16.773 7.22703M21 12H18.75M18.364 18.364L16.773 16.773M12 18.75V21M7.22703 16.773L5.63604 18.364M5.25 12H3M7.22703 7.22703L5.63604 5.63604M15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p
                class="font-semibold text-sm/none text-neutral-800 dark:text-neutral-400"
              >
                {{ $t("lightTheme") }}
              </p>
            </div>
            <div class="px-3 py-4 flex flex-col gap-3">
              <p
                class="font-medium text-sm text-neutral-600 dark:text-neutral-400 text-start"
              >
                {{ $t("lightHelp") }}
              </p>
              <div
                class="mx-auto border border-neutral-300 rounded-lg group-hover:shadow-md overflow-hidden transition-all ease-in-out duration-500"
              >
                <img src="/icons/lightmode_laoder.png" class="h-40 w-60" />
              </div>
            </div>
          </div>
          <div
            @click="changeTheme('dark')"
            class="border rounded-lg overflow-hidden cursor-pointer transition-all ease-in-out duration-300 customScale"
            :class="
              theme == 'dark'
                ? 'border-blue-600 bg-blue-50/20 dark:border-blue-400 dark:bg-blue-400/10'
                : 'border-neutral-300'
            "
          >
            <div class="p-3 flex gap-2 items-center bg-neutral-100">
              <svg
                width="20"
                class="text-neutral-800 dark:text-neutral-200"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.7519 15.0019C20.597 15.4839 19.3296 15.75 18 15.75C12.6152 15.75 8.25 11.3848 8.25 5.99999C8.25 4.67039 8.51614 3.40296 8.99806 2.24805C5.47566 3.71785 3 7.19481 3 11.25C3 16.6348 7.36522 21 12.75 21C16.8052 21 20.2821 18.5243 21.7519 15.0019Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"  
                />
              </svg>
              <p
                class="font-semibold text-sm/none text-neutral-800 dark:text-neutral-400"
              >
                {{ $t("darkTheme") }}
              </p>
            </div>
            <div class="px-3 py-4 flex flex-col gap-3">
              <p
                class="font-medium text-sm text-neutral-600 dark:text-neutral-400 text-start"
              >
                {{ $t("darkHelp") }}
              </p>
              <div
                class="mx-auto border border-neutral-300 rounded-lg group-hover:shadow-md overflow-hidden transition-all ease-in-out duration-500"
              >
                <img src="/icons/darkmode_loader.png" class="h-40 w-60" />
              </div>
            </div>
          </div>
        </div> -->
      <!-- </div> -->
      <!-- <div
        class="flex flex-col gap-10 h-full max-h-[500px] min-h-[500px] overflow-y-scroll"
        v-if="currentStep == 5"
      >
        <div class="flex flex-col gap-3">
          <h1 class="font-bold text-2xl text-neutral-800 dark:text-neutral-200">
            {{ $t("readyStart") }}
          </h1>
          <p
            class="font-medium text-base text-neutral-600 dark:text-neutral-400"
          >
            {{ $t("topTutorials") }}
          </p>
        </div>
        <div class="w-full flex flex-col gap-3">
          <NuxtLink
            target="_blank"
            href="https://help.aevent.com/en/articles/8461931-read-this-first-recommended-reading"
            ><div
              class="px-5 py-5 bg-neutral-100/40 w-full border border-neutral-300/60 rounded-md flex gap-3.5 items-center hover:bg-neutral-200/40 transition-all ease-in-out duration-300 cursor-pointer"
            >
              <h1 class="font-medium text-lg/none text-neutral-800">
                {{ $t("readMe") }}
              </h1>
            </div></NuxtLink
          >

          <NuxtLink
            target="_blank"
            href="https://help.aevent.com/en/collections/6620812-quick-start-guides"
          >
            <div
              class="px-5 py-5 bg-neutral-100/40 w-full border border-neutral-300/60 rounded-md flex gap-3.5 items-center hover:bg-neutral-200/40 transition-all ease-in-out duration-300 cursor-pointer"
            >
              <h1 class="font-medium text-lg/none text-neutral-800">
                {{ $t("quickStart") }}
              </h1>
            </div></NuxtLink
          >

          <NuxtLink
            target="_blank"
            href="https://help.aevent.com/en/collections/3383379-integrations"
          >
            <div
              class="px-5 py-5 bg-neutral-100/40 w-full border border-neutral-300/60 rounded-md flex gap-3.5 items-center hover:bg-neutral-200/40 transition-all ease-in-out duration-300 cursor-pointer"
            >
              <h1 class="font-medium text-lg/none text-neutral-800">
                {{ $t("integrationTutorials") }}
              </h1>
            </div>
          </NuxtLink>
          <NuxtLink target="_blank" href="https://help.aevent.com">
            <div
              class="px-5 py-5 bg-neutral-100/40 w-full border border-neutral-300/60 rounded-md flex gap-3.5 items-center hover:bg-neutral-200/40 transition-all ease-in-out duration-300 cursor-pointer"
            >
              <h1 class="font-medium text-lg/none text-neutral-800">
                {{ $t("supportCenter") }}
              </h1>
            </div></NuxtLink
          >
        </div>
      </div> -->
      <div class="relative flex h-fit justify-end items-end">
        <div class="flex justify-center w-full">
          <ReusableUIButtonsButtonPrimary
            @click="handleNext()"
            size="medium"
            :disabled="disable"
            :text="currentStep != 3 ? 'Next' : 'Lets Begin!'"
            class="self-center"
          />
        </div>
        <div
          class="flex group items-center justify-center gap-0.5 cursor-pointer text-neutral-600 dark:text-neutral-400 hover:text-neutral-800 transition-all ease-in-out duration-500 absolute left-0 top-3"
          v-if="currentStep !== 1"
          @click="handleBack()"
        >
          <svg
            width="20"
            class="group-hover:-translate-x-0.5 transition-all ease-in-out duration-500"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 18L9 12L15 6"
              stroke="currentColor"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="font-semibold text-sm leading-none">Back</p>
        </div>
      </div>
      <div class="flex gap-1.5 justify-center">
        <div
          class="w-2 h-2 rounded-full transition-all ease-in-out duration-300"
          :class="currentStep == items.step ? 'bg-blue-500' : 'bg-neutral-400'"
          v-for="items in stepsArray"
        ></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { storeToRefs } from "pinia";
// import { useCommonStore } from "~~/store/common";
import { useAuthStore } from "~~/store/auth";
const { t } = useI18n();
const authStore = useAuthStore();
const { getUser, termsOfService } = storeToRefs(authStore);
// const commonStore = useCommonStore();
// const colorMode = useColorMode();
const router = useRouter();
const stepsArray = ref([{ step: 1 }, { step: 2 }, { step: 3 }]);
const userInfo = ref([
  {
    icon: "",
    heading: "Starter",
    title: t("apprenticeTitle"),
    type: "beginner",
  },
  {
    icon: "",
    heading: "Experienced",
    title: t("skilledTitle"),
    type: "advanced",
  },
  {
    icon: "",
    heading: "Expert",
    title: t("expertTitle"),
    type: "expert",
  },
]);
let currentStep = ref(1);

const shouldShow = computed(() => {
  return (
    getUser.value.email &&
    (getUser.value.agreed_current_terms === false ||
      typeof agreedModel.value === "boolean")
  );
});

const reset = () => {
  agreedModel.value = null;
  router.push("/?t=n");
};

const handleNext = () => {
  if (currentStep.value >= 3) return reset();
  currentStep.value = currentStep.value + 1;
};

const handleBack = () => {
  if (currentStep.value <= 1) return false;
  currentStep.value = currentStep.value - 1;
};

// Terms of Service

const agreedModel = ref(null);
const stillReading = ref(true);

const agreed = computed({
  get() {
    return agreedModel.value;
  },
  set(val) {
    if (val && !stillReading.value) agreedModel.value = true;
    if (!val) agreedModel.value = false;
  },
});

nextTick(authStore.fetchTerms);
watch(getUser, authStore.fetchTerms);

const agree = async () => {
  if (agreed.value) {
    currentStep.value = 2;
    await authStore.agreeCurrentTerms();
  }
};

const scroll = (element) => {
  const scrollPosition =
    element?.target?.scrollHeight -
    element?.target?.scrollTop -
    element?.target?.clientHeight;
  if (scrollPosition < 20) {
    stillReading.value = false;
  }
};

// Experience Level

const experienceLevel = ref(null);
const setExperienceLevel = async (level) => {
  experienceLevel.value = level;
  await authStore.setExperienceLevel(level.toLowerCase());
};

// Theme

// const theme = ref(null);
// const changeTheme = async (selection) => {
//   theme.value = selection;
//   await authStore.changeTheme(selection);
// };
const disable = ref(false);
watch(() => {
  if (experienceLevel.value == null && currentStep.value == "3") {
    disable.value = true;
  } else {
    disable.value = false;
  }
});
const { locale } = useI18n();
</script>

<style lang="scss">
$tos-width: 50vw;
$bg-dark: #1e2023;
$bg-light: #fff;
$tos-contents-width: 30vw;

.tos {
  width: 64em;
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &-header {
    text-align: center;
    font-size: 1.2rem;
  }
  &-contents {
    padding-left: 1em;
    padding-right: 1em;
    position: relative;
    overflow-y: auto;
    padding-bottom: 20px;
  }
  &-agree {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-top: 1em;
    padding-bottom: 2em;
    &-btn {
      width: 20em !important;
    }
    &-checkbox {
      margin-top: 15px;
    }
  }
}

.tos-text * {
  margin-bottom: 20px;
}

.tos-text h1 {
  margin-top: 40px;
}

.dark {
  .tos {
    background-color: $bg-dark;
    &-header {
      color: white;
    }
    &-contents {
      &-text > * {
        color: white !important;
      }
    }
    &-agree {
      background-color: $bg-dark;
    }
  }
}

.light {
  .tos {
    background-color: $bg-light;
    &-agree {
      background-color: $bg-light;
    }
  }
}

.dark * .tos-text > * {
  color: #fff !important;
}
* .tos-text > h1 {
  font-size: 1.3rem;
  text-align: center;
}
* .tos-text > h2 {
  font-size: 1.1rem;
}
</style>
