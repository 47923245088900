<template>
  <div class="flex font-manrope" :class="colorMode.preference">
    <ReusableUISideNav />
    <div v-if="media">
      <MediaCompsMediaSideNav />
    </div>
    <!-- @showWarning="handleShowWarning" -->
    <div class="flex flex-col w-full bg-neutral-100 dark:bg-neutral-950">
      <!-- New media sidebar  -->
      <div class="w-full">
        <!-- global top Nav  -->
        <ReusableUITopNav
          :theme="colorMode.preference"
          @toggleTheme="toggleTheme"
          @showStats="showStats = true"
        />
        <!-- Media layout handling  -->
      </div>
      <!-- @showWarning="handleShowWarning" -->
      <div
        v-if="isAllLoaded"
        class="w-full h-full overflow-y-auto transition-all ease-in-out duration-700 content-scroll"
      >
        <div class="flex w-full mx-auto h-[calc(100vh-62px)]">
          <slot />
        </div>
      </div>
      <div
        v-if="!isAllLoaded"
        class="flex flex-col items-center justify-center h-[calc(100vh-62px)] transition-all ease-in-out duration-700"
      >
        <div class="w-[300px] flex justify-center items-center">
          <ReusableUIAppLoader />
        </div>
      </div>
    </div>
    <!-- Intercom -->

    <ReusableUISubscriptionHandler />
    <!-- Popups -->
    <component
      :is="showStats ? stats : showWarning ? warning : ''"
      source="timeline"
      :route="routeToRedirect"
      :loading="saveLoader"
      @closeStats="showStats = false"
      @cancel="handleModalChange"
      @close="showWarning = false"
    ></component>
    <ReusableUIModalsWelcomeModal />
  </div>
</template>
<style>
.font-manrope {
  font-family: "Manrope";
}
</style>
<script setup>
import { storeToRefs } from "pinia";
import { useCommonStore } from "~~/store/common";
import { useAuthStore } from "~~/store/auth";
import "/public/assets/css/intercom-fix.css";
const commonStore = useCommonStore();
const authStore = useAuthStore();
const { isAllLoaded } = storeToRefs(commonStore);
const { getUser } = storeToRefs(authStore);
const showStats = ref(false);
const showWarning = ref(false);
const stats = resolveComponent("ReusableUIModalsUsageStats");
const warning = resolveComponent("ReusableUIModalsWarningModel");
const route = useRouter();
const colorMode = useColorMode();
const routeToRedirect = ref("");
const saveLoader = ref(false);
const props = defineProps({
  media: Boolean,
  currentTab: String,
  refreshing: Boolean,
});

if (typeof window !== "undefined" && window) {
  localStorage.setItem("currentPath", window.location.href);
}
const toggleTheme = async (item) => {
  colorMode.preference = item;
  getUser.value["profile"]["theme"] = item;
  await authStore.changeTheme(item);
};
const handleModalChange = (val) => {
  commonStore.setActiveTab(val.split("/")[1]);
  showWarning.value = false;
  route.push(val);
};
</script>
