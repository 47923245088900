<template>
  <div>
    <label class="labelcontainer dark:text-neutral-200"
      >{{ title }}
      <input
        ref="time-checkbox"
        class="time-checkbox"
        type="checkbox"
        checked="checked"
        v-model="internalModel"
        @click="isChecked"
        :disabled="disabled"
      />
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: String,
      default: "",
    },
    unchecked: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
      return {
          title: "",
          internalModel: null,
      }
  },
  computed: {
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.internalModel = val;
        this.$emit("update:modelValue", val);
      },
    },
  },
  mounted() {
    this.title = this.checked;
    this.internalModel = this.modelValue;
  },
  methods: {
    isChecked() {
      let inputElements = this.$refs["time-checkbox"];
      if (inputElements.checked) {
        this.$emit("checkedEvent");
        this.$emit("update:modelValue", true);
        this.title = this.checked;
      } else {
        this.title = this.unchecked;
        this.$emit("update:modelValue", false);
        this.$emit("uncheckedEvent");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* The container */
.labelcontainer {
  display: block;
  position: relative;
  padding-left: 35px;

  cursor: pointer;
  font-size: 20px;
  line-height: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.labelcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border-radius: 6px;
  border: solid 1px #979698;
}

/* On mouse-over, add a grey background color */
.labelcontainer:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.labelcontainer input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.labelcontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.labelcontainer .checkmark:after {
  left: 8px;
  top: 3px;
  width: 7px;
  height: 15px;
  border: solid #0070b2;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.theme-dark .labelcontainer {
  color: #fff;
}
</style>
