<template>
  <div
    class="min-w-[228px] max-w-[228px] bg-white dark:bg-neutral-900 py-8 px-4 hidden xl:flex flex-col justify-between h-screen overflow-auto border-r border-neutral-200 dark:border-neutral-700"
  >
    <div class="flex flex-col gap-3">
      <h1
        class="text-neutral-500 dark:text-neutral-400 text-xs tracking-widest"
      >
        MEDIA
      </h1>
      <div class="flex flex-col gap-1">
        <div
          v-for="item in navOptions"
          @click="handleChange(item)"
          class="flex gap-2 items-center p-3 rounded-lg cursor-pointer hover:bg-neutral-100 hover:text-blue-600 hover:dark:bg-neutral-800 hover:dark:text-blue-400"
          :class="
            route.path === item.link
              ? 'bg-neutral-100 text-blue-600 dark:bg-neutral-800 dark:text-blue-400'
              : 'text-neutral-600 dark:text-neutral-400'
          "
        >
          <div v-html="item.icon" class="shrink-0"></div>
          <p class="font-semibold text-sm">{{ item.title }}</p>
        </div>
      </div>
    </div>
    <!-- <div class="flex flex-col gap-2">
      <div
        class="pt-2 px-3 flex flex-col gap-3 bg-neutral-50 dark:bg-neutral-800 border border-neutral-100 dark:border-neutral-700 rounded-lg"
      >
        <div class="flex justify-between">
          <p class="font-bold text-sm text-neutral-950 dark:text-neutral-50">
            Storage
          </p>
          <p class="font-bold text-sm text-blue-600 dark:text-blue-400">
            58% used
          </p>
        </div>
        <div class="semiProgress-bar">
          <div class="semiBarOverflow">
            <div class="semiBar"></div>
          </div>
          <div class="absolute inset-0 mx-auto top-8 flex flex-col">
            <span
              class="font-extrabold text-xl text-blue-600 dark-text-blue-400"
              >2.4GB</span
            >
            <span
              class="font-medium text-xs text-neutral-600 dark:text-neutral-400"
              >of 5GB</span
            >
          </div>
        </div>
      </div>
      <ReusableUIButtonsButtonSecondary text="Get more space" size="medium" />
    </div> -->
  </div>
</template>
<script setup>
import { useMediaStore } from "~~/store/media";

const router = useRouter();
const route = useRoute();
const mediaStore = useMediaStore();
const handleChange = (item) => {
  mediaStore.setUploadMediaToFolder("");
  router.push(item.link);
};
const navOptions = [
  {
    type: "files",
    title: "Files",
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_7926_293)">
<path d="M12.5 2.5V5.83333C12.5 6.05435 12.5878 6.26631 12.7441 6.42259C12.9004 6.57887 13.1123 6.66667 13.3333 6.66667H16.6667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 14.1667H9.16667C8.72464 14.1667 8.30072 13.9911 7.98816 13.6785C7.6756 13.366 7.5 12.942 7.5 12.5V4.16667C7.5 3.72464 7.6756 3.30072 7.98816 2.98816C8.30072 2.67559 8.72464 2.5 9.16667 2.5H12.5L16.6667 6.66667V12.5C16.6667 12.942 16.4911 13.366 16.1785 13.6785C15.8659 13.9911 15.442 14.1667 15 14.1667Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.3333 14.1668V15.8335C13.3333 16.2755 13.1577 16.6994 12.8451 17.012C12.5326 17.3246 12.1087 17.5002 11.6666 17.5002H5.83329C5.39127 17.5002 4.96734 17.3246 4.65478 17.012C4.34222 16.6994 4.16663 16.2755 4.16663 15.8335V7.50016C4.16663 7.05814 4.34222 6.63421 4.65478 6.32165C4.96734 6.00909 5.39127 5.8335 5.83329 5.8335H7.49996" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_7926_293">
<rect width="20" height="20" fill="currentColor"/>
</clipPath>
</defs>
</svg>
`,
    link: "/media-new",
  },
  {
    type: "images",
    title: "Images",
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_7926_300)">
<path d="M12.5 6.6665H12.5067" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.5 5C2.5 4.33696 2.76339 3.70107 3.23223 3.23223C3.70107 2.76339 4.33696 2.5 5 2.5H15C15.663 2.5 16.2989 2.76339 16.7678 3.23223C17.2366 3.70107 17.5 4.33696 17.5 5V15C17.5 15.663 17.2366 16.2989 16.7678 16.7678C16.2989 17.2366 15.663 17.5 15 17.5H5C4.33696 17.5 3.70107 17.2366 3.23223 16.7678C2.76339 16.2989 2.5 15.663 2.5 15V5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.5 13.3332L6.66667 9.16652C7.44 8.42236 8.39333 8.42236 9.16667 9.16652L13.3333 13.3332" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.6666 11.6668L12.5 10.8335C13.2733 10.0893 14.2266 10.0893 15 10.8335L17.5 13.3335" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_7926_300">
<rect width="20" height="20" fill="currentColor"/>
</clipPath>
</defs>
</svg>
`,
    link: "/media-new/images",
  },
  {
    type: "videos",
    title: "Videos",
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g clip-path="url(#clip0_7926_308)">
               <path d="M12.5 8.33319L16.2942 6.43652C16.4212 6.37305 16.5623 6.34309 16.7042 6.34948C16.846 6.35587 16.9839 6.39839 17.1047 6.47303C17.2255 6.54766 17.3252 6.65192 17.3944 6.77591C17.4636 6.89991 17.4999 7.03953 17.5 7.18152V12.8182C17.4999 12.9602 17.4636 13.0998 17.3944 13.2238C17.3252 13.3478 17.2255 13.4521 17.1047 13.5267C16.9839 13.6013 16.846 13.6438 16.7042 13.6502C16.5623 13.6566 16.4212 13.6267 16.2942 13.5632L12.5 11.6665V8.33319Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
               <path d="M2.5 6.66667C2.5 6.22464 2.67559 5.80072 2.98816 5.48816C3.30072 5.17559 3.72464 5 4.16667 5H10.8333C11.2754 5 11.6993 5.17559 12.0118 5.48816C12.3244 5.80072 12.5 6.22464 12.5 6.66667V13.3333C12.5 13.7754 12.3244 14.1993 12.0118 14.5118C11.6993 14.8244 11.2754 15 10.8333 15H4.16667C3.72464 15 3.30072 14.8244 2.98816 14.5118C2.67559 14.1993 2.5 13.7754 2.5 13.3333V6.66667Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
               </g>
               <defs>
               <clipPath id="clip0_7926_308">
            <rect width="20" height="20" fill="currentColor"/>
</clipPath>
</defs>
</svg>
`,
    link: "/media-new/videos",
  },
  {
    type: "audio",
    title: "Audio",
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_7926_314)">
<path d="M3.33337 12.5002C3.33337 12.0581 3.50897 11.6342 3.82153 11.3217C4.13409 11.0091 4.55801 10.8335 5.00004 10.8335H5.83337C6.2754 10.8335 6.69932 11.0091 7.01188 11.3217C7.32445 11.6342 7.50004 12.0581 7.50004 12.5002V15.0002C7.50004 15.4422 7.32445 15.8661 7.01188 16.1787C6.69932 16.4912 6.2754 16.6668 5.83337 16.6668H5.00004C4.55801 16.6668 4.13409 16.4912 3.82153 16.1787C3.50897 15.8661 3.33337 15.4422 3.33337 15.0002V12.5002Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.5 12.5002C12.5 12.0581 12.6756 11.6342 12.9882 11.3217C13.3007 11.0091 13.7246 10.8335 14.1667 10.8335H15C15.442 10.8335 15.866 11.0091 16.1785 11.3217C16.4911 11.6342 16.6667 12.0581 16.6667 12.5002V15.0002C16.6667 15.4422 16.4911 15.8661 16.1785 16.1787C15.866 16.4912 15.442 16.6668 15 16.6668H14.1667C13.7246 16.6668 13.3007 16.4912 12.9882 16.1787C12.6756 15.8661 12.5 15.4422 12.5 15.0002V12.5002Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.33337 12.5002V10.0002C3.33337 8.23205 4.03575 6.53636 5.286 5.28612C6.53624 4.03588 8.23193 3.3335 10 3.3335C11.7682 3.3335 13.4638 4.03588 14.7141 5.28612C15.9643 6.53636 16.6667 8.23205 16.6667 10.0002V12.5002" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_7926_314">
<rect width="20" height="20" fill="currentColor"/>
</clipPath>
</defs>
</svg>
`,
    link: "/media-new/audios",
  },
  {
    type: "nifty",
    title: "Nifty Images",
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_7926_321)">
<path d="M7.5 12.5H5C4.33696 12.5 3.70107 12.2366 3.23223 11.7678C2.76339 11.2989 2.5 10.663 2.5 10V5C2.5 4.33696 2.76339 3.70107 3.23223 3.23223C3.70107 2.76339 4.33696 2.5 5 2.5H10C10.663 2.5 11.2989 2.76339 11.7678 3.23223C12.2366 3.70107 12.5 4.33696 12.5 5V7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.5 10C7.5 9.33696 7.76339 8.70107 8.23223 8.23223C8.70107 7.76339 9.33696 7.5 10 7.5H15C15.663 7.5 16.2989 7.76339 16.7678 8.23223C17.2366 8.70107 17.5 9.33696 17.5 10V15C17.5 15.663 17.2366 16.2989 16.7678 16.7678C16.2989 17.2366 15.663 17.5 15 17.5H10C9.33696 17.5 8.70107 17.2366 8.23223 16.7678C7.76339 16.2989 7.5 15.663 7.5 15V10Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.5 9.99999L4.41333 8.08666C4.78996 7.71009 5.30074 7.49854 5.83333 7.49854C6.36592 7.49854 6.87671 7.71009 7.25333 8.08666L7.5 8.33332" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.6666 11.25V13.75L13.75 12.5L11.6666 11.25Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.83337 5V5.00667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_7926_321">
<rect width="20" height="20" fill="currentColor"/>
</clipPath>
</defs>
</svg>
`,
    link: "/media-new/niftyImages",
  },
];
</script>
